import { useEffect } from 'react'
import { useRouter } from 'next/router'
import axios from 'axios';
import '../styles/globals.css'
import { useGraphQLClient } from '../lib/graphql-client'
import { ClientContext } from 'graphql-hooks'
import { Provider } from '../store'
import Layout from '../components/Layout';
import GoogleAnalytics from "../components/GoogleAnalytics";
import { HighlightInit } from '@highlight-run/next/client'
import { ErrorBoundary } from '../components/error-boundary'

export default function App({ Component, pageProps }) {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      const url_parts = window.location.hostname.split('.');
      if (url_parts.length > 2) {
        access_log(url_parts[0])
      }
    }
    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])

  const access_log = (subdomain) => {
    const axiosInstance = axios.create({
      baseURL: `${process.env.NEXT_PUBLIC_API}`,
      headers: {
        "content-type": "application/json",
      },
    });
    (async () => {
      return await axiosInstance
        .post("access_logs", {
          subdomain: subdomain,
        })
    })();
  };

  const graphQLClient = useGraphQLClient(pageProps.initialGraphQLState)

  return (
    <ClientContext.Provider value={graphQLClient}>
      <Provider>
        <Layout>
          <ErrorBoundary>
            <GoogleAnalytics />
            <HighlightInit
              projectId={process.env.NEXT_PUBLIC_HIGHLIGHT}
              serviceName="my-nextjs-frontend"
              tracingOrigins
              networkRecording={{
                enabled: true,
                recordHeadersAndBody: true,
                urlBlocklist: [],
              }}
              excludedHostnames={['127.0.0.1', 'localhost', 'localhost:3000']}
            />
            <Component {...pageProps} />
          </ErrorBoundary>
        </Layout>
      </Provider>
    </ClientContext.Provider>
  );
}

